import React from "react"
import PageLayout from "../PageLayout"
import { PageMargin, PageTitle } from "../GlobalStyles"
import styled from "styled-components/macro"

const CareerIframe = styled.iframe`
  width: 800px;
  height: 1000px;
  border: none;
`

const Career = () => {
  return (
    <PageLayout>
      <PageMargin>
        <PageTitle>Career</PageTitle>
        {/* iframe contents located in static/careerScript.html */}
        {/* for now must manually place script src in script there */}
        <CareerIframe
          src="/careerScript.html"
          title="Career Script"
        ></CareerIframe>
      </PageMargin>
    </PageLayout>
  )
}

export default Career
